import React from "react"
import DebitCardInsurance from "../../../components/body/pages/en-ng/legal/debitCards"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const DebitCardsInsurancePage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/cards/"}
      title="Debit Card - Terms and Conditions"
      description="Kuda Debit Card Legal Terms and Policies"
    />
    <DebitCardInsurance />
  </Layout>
)

export default DebitCardsInsurancePage
